import React from "react"
import { graphql } from "gatsby"
import Meta from "../components/common/Meta"
import PositionLayout from "../components/open-positions/PositionLayout"
import styled from "styled-components"
import ApplyForm from "../components/common/form/ApplyForm"
import PositionHeader from "../components/open-positions/PositionHeader"
import PositionFactsMobile from "../components/open-positions/position-facts/PositionFactsMobile"

const ApplyFormContainer = styled.section`
  grid-area: jobcontent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  gap: 0.5rem;
  position: relative;

  &:before {
    @media (min-width: 64em) {
      content: "";
      position: absolute;
      left: 0;
      width: 5px;
      height: 800px;
      background-color: var(--clr-secondary-dark);
    }
  }

  @media (min-width: 64em) {
    margin-top: 5.125rem;
    margin-left: 1.845rem;
    padding-left: 4rem;
    padding-right: 5.31rem;
    margin-bottom: 9.5rem;
  }
`

const Padding = styled.div`
  margin-top: 4.4375rem;
`

export const Head = ({ data }) => (
  <Meta
    title={`Rebase - Apply for ${data?.positionMd?.frontmatter.mainTitle}`}
    description={`Apply form for ${data?.positionMd?.frontmatter.mainTitle}`}
  />
)

const PositionApplyTemplate = ({ data, pageContext }) => {
  console.log(pageContext)
  const {
    mainTitle,
    remote,
    supervisor,
    salary,
    salary_date,
    rate,
    rate_date,
  } = data?.positionMd?.frontmatter

  return (
    <PositionLayout data={data}>
      <ApplyFormContainer>
        <PositionHeader title={mainTitle} simple={true} remote={remote} />
        <ApplyForm
          exploreButton={false}
          positionField={false}
          recruiteeId={pageContext.recruitee_id}
        />
        <Padding />
        <PositionFactsMobile
          image={data.image}
          supervisor={supervisor}
          salary={salary}
          salaryDate={salary_date}
          rate={rate}
          rateDate={rate_date}
        />
      </ApplyFormContainer>
    </PositionLayout>
  )
}

export default PositionApplyTemplate

export const query = graphql`
  query ApplyPage($slug: String, $supervisorImage: String) {
    image: file(relativePath: { eq: $supervisorImage }) {
      childImageSharp {
        gatsbyImageData(width: 180, quality: 80, placeholder: BLURRED)
      }
    }
    positionMd: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        mainTitle
        slug
        remote
        supervisor
        salary
        rate
        story_slug
      }
      htmlAst
      headings {
        value
      }
    }
  }
`
